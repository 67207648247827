.scenario__card {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;
    border: 1px dashed #383838;
    padding: 5px;
    border-radius: 15px;
    transition: all 0.5s ease;

}

.scenario__card:hover {

    cursor: pointer;
    transform: scale(1.02);
    transition: all 0.5s ease;

}

.scenario {

    display: flex;
    flex-direction: column;
    width: fit-content;

}

.scenario__title, .scenario__description, .scenario__last__run {
    padding: 8px;
}

.scenario__title {

    font-size: 1.5rem;
    font-weight: bold;
    color: #383838;

}

.scenario__description {

    font-size: 1.2rem;
    color: #585858;

}

.scenario__last__run {
    color: #787878;
}

.success {
    background: #b3ffcf;
}

.error {
    background: #ffc3c3;
}

.not_run {
    background: #b3cfff;
}

.inactive {
    background: #e7e7d3;
}

.scenario__active__switch {

    display: flex;
    align-items: center;
    margin-right: 30px;

}

.switch {

    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

}

.switch__input {

    opacity: 0;
    width: 0;
    height: 0;

}

.switch__slider {

    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

}
  
.switch__slider:before {

    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;

}
  
.switch__input:checked + .switch__slider {
    background-color: #2196F3;
}

.switch__input:focus + .switch__slider {
    box-shadow: 0 0 1px #2196F3;
}
  
.switch__input:checked + .switch__slider:before {

    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);

}

.switch__slider.switch__round {
    border-radius: 34px;
}
  
.switch__slider.switch__round:before {
    border-radius: 50%;
}
