.navbar {
    width: 100%;
}

.navbar__logo {

    width: 96%;
    padding: 10px 2%;
    background: #e8c0e8;
    border-bottom: 1px solid #a0a0a0;

}

.navbar__logo img {

    width: 64px;
    height: 64px;

}

.navbar__links {

    display: flex;
    width: 96%;
    padding: 10px 2%;
    border-bottom: 1px solid #a0a0a0;

}

.navbar__link {

    margin: 0 10px;
    color: #383838;
    font-size: 1.3rem;
    text-decoration: none;
    transition: all 0.5s ease;

}

.navbar__link:hover {

    cursor: pointer;
    color: #903890;
    transition: all 0.5s ease;

}

.navbar__link__first {
    margin-left: auto;
}

.navbar__link__active {
    font-weight: bold;
}

.navbar__link__logout {
    
    background: transparent;
    border: none;

}
