.button {

    width: fit-content;
    padding: 5px 15px;
    border-radius: 12px;
    font-size: 1.3rem;
    transition: all 0.5s ease;

}

button:hover {
    cursor: pointer;
}

.success {

    background: #4CAF50;
    border: 2px solid #1C7F10;
    color: #333333;

}

.success:hover {

    background: #1C5F10;
    border: 2px solid #083F10;
    color: #e0e0e0;

}

.success:active {

    background: #1C4810;
    border: 2px solid #082F10;
    color: #f0f0f0;

}

.danger {

    background: #f85346;
    border: 2px solid #7F0F10;
    color: #1F1F1F;

}

.danger:hover {

    background: #C72F26;
    border: 2px solid #4F0F10;
    color: #e0e0e0;

}

.danger:active {

    background: #A71F16;
    border: 2px solid #3F0F10;
    color: #f0f0f0;

}
