.wrapper {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;

}

.popup {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
    background: #f0d0ff;
    border-radius: 15px;

}

.popup__header {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 10px auto;
    border-bottom: 1px solid #383838;
    padding: 10px;

}

.popup__title, .popup__close {
    color: #383838;
}

.popup__title {
    font-size: 1.5rem;
}

.popup__close {
    font-size: 2.5rem;
}

.popup__close:hover {
    cursor: pointer;
}

.popup__content {

    width: 90%;
    margin: 10px auto;
    padding: 10px;
    min-height: 100px;

}
