.profile__wrapper {
  
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 96%;
    margin: 20px auto;

}

.title {

    width: fit-content;
    margin: 30px auto;
    font-size: 2.3rem;
    color: #8038b0;

}

.subtitle {

    width: fit-content;
    margin: 5px;
    margin-left: 5%;
    font-size: 1.8rem;
    color: #383838;

}

.profile__form_inputs {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 96%;
    margin: 20px auto;

}

.popup__wrapper {
    width: 600px;
}

.popup__text {

    width: 90%;
    margin: 10px auto;
    padding: 10px;
    font-size: 1.5rem;
    color: #383838;
    text-align: center;

}

.popup__buttons {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 96%;
    margin: 20px auto;

}
