.scenario__form  {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 10px auto;
    padding: 5px 2%;

}
