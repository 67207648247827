.regauth__form_submit {

    width: 200px;
    margin: 15px auto;
    padding: 10px;
    border: 2px solid #601890;
    border-radius: 15px;
    color: #202020;
    background: #b068e0;
    font-size: 1.5rem;
    transition: all 0.5s ease;

}

.regauth__form_submit:hover {

    cursor: pointer;
    color: #d0d0d0;
    background: #8038b0;
    border-color: #400870;
    transition: all 0.5s ease;

}

.regauth__form_submit:active {

    color: #e0e0e0;
    background: #601890;
    border-color: #200850;
    transition: all 0.5s ease;

}
