.sidebar {

    width: 20%;
    height: fit-content;
    background: #f8dbf8;
    padding: 20px;
    border-radius: 20px;

}

.sidebar__link a {

    display: block;
    color: #383838;
    font-size: 2rem;
    margin: 10px;
    border-bottom: 1px solid #888;
    text-decoration: none;

}

.sidebar__link__active a {
    font-weight: bold;
}
