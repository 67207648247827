.scenarios__wrapper {
  
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 96%;
    margin: 20px auto;

}

.title {

    width: fit-content;
    margin: 30px auto;
    font-size: 2.2rem;
    color: #8038b0;

}

.no__scenarios__title {

    width: fit-content;
    margin: 20px auto;
    font-size: 1.5rem;
    color: #585858;
    text-align: center;

}

.create__scenario__button__wrapper {

    width: fit-content;
    margin: 20px auto;

}
