.scenario__form__input {
  
    width: 300px;
    margin: 5px auto;
    border: 1px solid #7028a0;
    padding: 3px;
    border-radius: 5px;
    font-size: 1.5rem;
    transition: all 0.5s ease;

}

.scenario__form__input:focus {

    outline: none;
    border: 2px solid #c038b0;
    transition: all 0.5s ease;

}
