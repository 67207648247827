.wrapper {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;

}

.loader {

    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px dashed #e04090;
    animation: spin 2s linear infinite;

}

@keyframes spin {
    
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }

}
