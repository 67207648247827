.regauth__form  {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96%;
    padding: 10px 2%;

}
