.wrapper {

    width: 38%;
    margin: 50px auto;
    padding: 20px 1%;

}

.title {

    width: fit-content;
    margin: 20px auto;
    font-size: 2.5rem;
    color: #8038b0;

}
