.title {

    width: fit-content;
    margin: 30px auto;
    font-size: 2.2rem;
    color: #8038b0;

}

.scenario__wrapper {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 20px auto;
    padding: 20px;

}

.sidebar {
    width: 30%;
}

.scenario__form__button__wrapper, .delete__button__wrapper {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 96%;
    margin: 5px auto;

}

.delete__button__wrapper {
    margin-bottom: 20px;
}

.palette, .diagram {

    background: #e0e0e0;
    border-radius: 12px;

}

.palette {

    width: 90%;
    height: 450px;
    margin: 5px auto;

}

.diagram {

    width: 65%;
    height: 70vh;

}

.popup__form {

    width: 90%;
    margin: 10px auto;

}

.popup__input__label__wrapper {

    width: 90%;
    margin: 15px auto;

}

.popup__input__label {

    display: block;
    margin: 5px 2px;
    font-size: 1.5rem;
    color: #383838;

}

.popup__input, .popup__textarea, .popup__select {
  
    display: block;
    width: 100%;
    border: 1px solid #7028a0;
    padding: 3px;
    border-radius: 5px;
    font-size: 1.5rem;
    transition: all 0.5s ease;

}

.popup__input:focus, .popup__textarea:focus, .popup__select:focus {

    outline: none;
    border: 2px solid #c038b0;
    transition: all 0.5s ease;

}

.popup__textarea {

    resize: none;
    height: 200px;

}

.buttons__wrapper {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin: 25px auto;

}

.popup__text {

    width: 800px;
    margin: 20px auto;
    font-size: 1.2rem;
    text-align: center;

}

.delete__popup__wrapper {
    width: 600px;
}

.delete__popup__text {

    width: 90%;
    margin: 10px auto;
    padding: 10px;
    font-size: 1.5rem;
    color: #383838;
    text-align: center;

}

.delete__popup__buttons {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 96%;
    margin: 20px auto;

}
