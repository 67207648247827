.flash {

    width: 60%;
    margin: 10px auto;
    margin-top: 50px;
    padding: 10px 20px;
    border-radius: 10px;
    color: #383838;
    text-align: center;
    font-size: 1.3rem;

}

.flash__success {

    background: #a0e8a0;
    border: 1px solid #58a058;

}

.flash__error {

    background: #e8a0a0;
    border: 1px solid #a05858;

}
