* {

    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;

}

body {
    background: #fff0f8;
}
