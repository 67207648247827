.profile__form__input__wrapper {
  
    width: 30%;
    margin: 15px auto;
    padding: 3px;

}

.profile__form__input__label {

    display: block;
    margin: 5px 2px;
    font-size: 1.5rem;
    color: #585858;

}

.profile__form__input {
  
    display: block;
    width: 100%;
    border: 1px solid #7028a0;
    padding: 3px;
    border-radius: 5px;
    font-size: 1.5rem;
    transition: all 0.5s ease;

}

.profile__form__input:focus {

    outline: none;
    border: 2px solid #c038b0;
    transition: all 0.5s ease;

}
