.profile__form_submit {

    width: fit-content;
    margin: 5px auto;
    padding: 5px 15px;
    border: 2px solid #601890;
    border-radius: 15px;
    color: #202020;
    background: #b068e0;
    font-size: 1.3rem;
    transition: all 0.5s ease;

}

.profile__form_submit:hover {

    cursor: pointer;
    color: #d0d0d0;
    background: #8038b0;
    border-color: #400870;
    transition: all 0.5s ease;

}

.profile__form_submit:active {

    color: #e0e0e0;
    background: #601890;
    border-color: #200850;
    transition: all 0.5s ease;

}
